import React from 'react';
import './start-role.scss';
import {Helmet} from "react-helmet";

const StartRole = (props) => {
  return (
    <div className="start-button-component">
      <Helmet>
        <title>Start a Room | Werewolf Remote</title>
        <link rel="canonical" href="https://www.werewolfremote.com/start" />
      </Helmet>
      <button
        name={props.name}
        className="decrease"
        disabled={props.name === "Werewolf" || props.name === "Villager" || props.name === "Seer" || props.name === "Witch"? props.value === 1: props.value === 0}
        onClick={props.decrement}>
        -
      </button>
      <span>{props.value}</span>
      <button
        name={props.name}
        className="increase"
        onClick={props.increment}
        disabled={props.name === "Seer" || props.name === "Witch"? true: props.total >= props.roomSize}>
        +
      </button>
    </div>
  )

};

export default StartRole;
