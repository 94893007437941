import React from 'react';
import { withRouter, Link } from "react-router-dom";
import StartRole from '../StartRole/start-role'
import Confirm from '../Confirm/confirm';
import './start.scss';
import {Dropdown, Container, Row, Col, Button, Modal} from "react-bootstrap";
import axios from 'axios';
import io from "socket.io-client";
import ReactGA from 'react-ga';
import * as Constants from "../Landing/roleConstants";
import RoleCard from "../Landing/landing";
import QuestionMarkImage from '../../images/roles/question-mark.png';

const MAX_ROOM_SIZE = 20
const roleNames = Constants.roles.map(role => role.name.toLowerCase());

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // before confirm
      roomSize: 5,
      rolesList: roleNames,
      modalShow: false,
      newRole:{"name": "", "details": "", "side": "villager"},
      newRoleList: [],
      newRoleValid: null,
      confirmed: false,
      // after confirm
      roomURL: "" || "Loading Room...",
      roomId: "",  // for getting the room id directly.
      roomStarted: false,
      roles: {"villager": 1, "werewolf": 1, "seer": 1, "witch": 1},
    };

    this.handleChange = this.handleChange.bind(this);
    this.roleValChange = this.roleValChange.bind(this);
    this.roleAdd = this.roleAdd.bind(this);
    this.settingSubmit = this.settingSubmit.bind(this);
    this.startAsHost = this.startAsHost.bind(this); // after confirm
    this.getTotal = this.getTotal.bind(this);
  }

  componentDidMount() {
    document.getElementById('root').style.filter = 'none';
    {this.renderRoles()}
    {this.renderCustomRoles()}
  }

  handleChange(event) {
    this.setState({
      roomSize: parseInt(event.target.value),
    });
  }

  setRoomSize(roomSize) {
    this.setState({roomSize});
  }

  settingSubmit(event) {
    event.preventDefault();
    console.log('setting completed.')
    this.setState({ confirmed: true })
    let body = this.generateSubmitData()
    fetch(`/rooms`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
          'Content-Type': 'application/json'
      }
    }).then(res => res.json())
        .then(data => {
          let settings = this.state.roles;
          ReactGA.event({
            category: 'Room',
            action: 'Created a Room',
            value: this.getTotal(),
            label: JSON.stringify(settings),
          });

          this.setState({
            confirmed: true,
            roomURL: `https://www.werewolfremote.com/game/${data.roomId}`,
            roomId: data.roomId,
          })
          // Create Room Namespace in socket.io
          io.connect().emit('room', data.roomId);
        })
        .catch(err => console.error("Error:", err));
  }

  generateSubmitData () {
    let roleDetails = Constants.roles.reduce((map, roleInfo) => {
      map[roleInfo.name.toLowerCase()] = roleInfo
      return map
    }, {})
    this.state.newRoleList.forEach(role => roleDetails[role.name.toLowerCase()] = role)
    for (const [role, quantity] of Object.entries(this.state.roles)) {
      roleDetails[role].quantity = quantity
    }
    return roleDetails
  }

//Function to increment
  increment = roleName => {
    this.setRole(roleName, this.getRole(roleName) + 1)
  }

  //Function to decrement
  decrement = roleName => {
    this.setRole(roleName, this.getRole(roleName) - 1)
  }

  //Function to get total no. of roles
  getTotal() {
    return Object.values(this.state.roles).reduce((a, b) => a + b);
  }

  setRole = (roleName, number) => {
    roleName = roleName.toLowerCase();
    let roles = this.state.roles;
    roles[roleName] = number;
    this.setState({roles});
  }

  getRole = roleName => {
    roleName = roleName.toLowerCase();
    return this.state.roles[roleName] ? this.state.roles[roleName] : 0;
  }

  handleClose = () => this.setState({
    modalShow: false,
    newRole:{"name": "", "details": "", "side": "villager"},
    newRoleValid: null
  })

  handleShow = () => this.setState({modalShow: true})

  roleValChange(event) {
    event.preventDefault();
    this.setState({ newRole: { ...this.state.newRole, [event.target.name]: event.target.value}, newRoleValid: null });
  }

  roleAdd(event) {
    event.preventDefault();

    let newRole = this.state.newRole
    let newRoleName = newRole.name.toLowerCase()

    if (this.state.rolesList.includes(newRoleName) || !/\S/.test(newRoleName)) {
      this.setState({newRoleValid: false});
    } else {
      this.setState(prevState => ({
        newRoleList: [...prevState.newRoleList, newRole],
        rolesList: [...prevState.rolesList, newRoleName],
        roles: { ...this.state.roles, [newRoleName]: 0}
      }))
     
      this.handleClose();
    }
  }


  //Function to enter game page(host)
  startAsHost(event) {
    event.preventDefault();
    // GET become host
    axios.get(`/rooms/${this.state.roomId}/hostregister`)
    .then(res => {
      ReactGA.event({
        category: 'User',
        action: 'Joined as a Host'
      });
      this.setState({roomStarted: true})
      this.props.history.push(`/game/${this.state.roomId}`);
    })
    .catch(error => {
      console.log(error)
    })

  }

  getRoomSizeMenu = (roomCapacity) =>  {
    let menuItems = []
    for (let i = 5; i <= roomCapacity; i++) {
      menuItems.push(<Dropdown.Item onClick={() => this.setRoomSize(i)}>{i}</Dropdown.Item>)
    }
    return (<>
      {menuItems}
    </>)
  }

  renderRoles() {
    
    let roles = []
    for (let role of Constants.roles) {
      roles.push(
        <Col xs={12} sm={6} md={4} >
          <div className={"role"}>
          <p style={role.name === "Alpha Werewolf" || role.name === "Werewolf"?{color:"sandybrown"}:{}}>{role.name}</p>
          <img src={role.thumbnail} alt={role.name} />
          <StartRole total={this.getTotal()} roomSize={this.state.roomSize} decrement={() => this.decrement(role.name)} increment={() => this.increment(role.name)} name={role.name} value={this.getRole(role.name)}/>
          </div>
        </Col>
      )
    }
    roles.push(
      <Col xs={12} sm={6} md={4} onClick={this.handleShow}>
        <div className={"role-add"}>
          <p>Add Custom Role</p>
          <p style={{fontSize:"40px"}}>+</p>
        </div>
      </Col>
    )
    return roles;
  }

  renderCustomRoles() {
    let newRoles = this.state.newRoleList
    let roles = []
    for (let newRole of newRoles) {
      roles.push(
        <Col xs={12} sm={6} md={4} >
          <div className={"role"}>
          <p style={newRole.side === "werewolf"?{color:"sandybrown"}:{}}>{newRole.name}</p>
          <img src={QuestionMarkImage} alt={newRole.name} />
          <StartRole total={this.getTotal()} roomSize={this.state.roomSize} decrement={() => this.decrement(newRole.name)} increment={() => this.increment(newRole.name)} name={newRole.name} value={this.getRole(newRole.name)}/>
          </div>
        </Col>
      )
    }
    return roles;
  }

  render() {
    if (!this.state.confirmed) {
      return (
        <div className={"start"}>

          <nav className={"navbar"}>
            <Link to="/" className="home">Home</Link>
          </nav>
      
          <div className="clouds"></div>
          <div className="clouds2"></div>
          <h1 className={"start__title"}>

            <span style={{color:"antiquewhite"}} id={"we"}>We</span>
            <span style={{color:"antiquewhite"}} id={"re"}>re</span>
            <span style={{color:"#867E6E"}} id={"wolf"}>wolf</span>
            <span id={"remote"}>Remote</span>
          </h1>
          <div className={"room-size-selection"}>
            <Dropdown>
              <Dropdown.Toggle style={{background:"none",border:"1px solid white"}}variant="secondary" id="dropdown-basic" size={"lg"}>
                Room Size: <span className={"room-size"}>{this.state.roomSize}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {this.getRoomSizeMenu(MAX_ROOM_SIZE)}
              </Dropdown.Menu>
            </Dropdown>
            <div className={"total-count"}>
              TOTAL: <span>{this.getTotal()}</span>
            </div>
          </div>
          <div className={"side-labels"}>
            <p style={{color:"sandybrown"}}>* Werewolf's Team</p><p style={{color:"antiquewhite"}}>* Villager's Team</p>
          </div>

          <Container>
            <Row>
            {this.renderRoles()}
            {this.renderCustomRoles()}
            </Row>
          </Container>

          <div className={"confirm"}>
            <Button variant="light" onClick={this.settingSubmit} disabled={this.getTotal() !== this.state.roomSize}>CONFIRM SETTINGS</Button>
          </div>

          {/* new custom role modal */}
          <div className={"custom-role-modal"}>
          <Modal show={this.state.modalShow} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
              <Modal.Title>Add a Custom Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form>
              <div className={"custom-row"}>
                <p>Role Name:</p>
                <input type="text" name="name" onChange={this.roleValChange} maxlength="10"/>
              </div>

              <div className={"custom-row"}>
                <p>Description:</p>
                <input type="text" name="details" onChange={this.roleValChange} maxlength="500"/>
              </div>
              
              <div className={"custom-row"}>
              <p>Side:</p>
                <select name="side" onChange={this.roleValChange}>
                  <option value="villager">Villager Team</option>
                  <option value="werewolf">Werewolf Team</option>
                </select>
              </div>   
            </form>
            </Modal.Body>
            <Modal.Footer>
              <p style={this.state.newRoleValid==false? {color:"darkred"}:{display:"none"}}>This role already exists/ should not be empty.</p>
              <Button variant="primary" onClick={this.roleAdd}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
          </div>
        </div>
      );
    } else {
      return (
        <Confirm startAsHost={this.startAsHost} roomURL={this.state.roomURL} roomStarted={this.state.roomStarted}/>
      )
    }

  }
}

export default withRouter(Start);
