import React, { Component } from 'react';
import {Col, Row, Image, Modal, Button} from "react-bootstrap";
import "../EndModal/end-modal.scss"

import {FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon} from "react-share";

class EndModal extends Component {
  render() {
    let modalRows = []
    for (let i=0; i<this.props.room.numberOfPlayers; i++) {
      let user = this.props.room.users[i];
      if (user && (user.role === "werewolf" || user.role === "alphawerewolf")) {
        modalRows.push(
          <Row className={"playerRow"} style={user.status === "Alive"? {color:"sandybrown"}: {color:"dimgrey"}}>
          <Col xs={2} md={3}>
          <Image src={user.picture} className={"player-image"} roundedCircle width={"30"} height={"30"}/>
          </Col>
          <Col xs={3} md={3}>
            {user.name.length <= 12? user.name: user.name.substring(0,11).concat("...")}
          </Col>
          <Col xs={3} md={3}>
            {user.role === "alphawerewolf"? "alpha werewolf": user.role}
          </Col>
          <Col xs={3} md={3}>
            {user.status}
          </Col>
        </Row>
        )
      } else if (user) {
        modalRows.push(
          <Row className={"playerRow"} style={user.status === "Alive"? {color:"lawngreen"}: {color:"dimgrey"}}>
            <Col xs={2} md={3}>
            <Image src={user.picture} className={"player-image"} roundedCircle width={"30"} height={"30"}/>
            </Col>
            <Col xs={3} md={3}>
              {user.name.length <= 12? user.name: user.name.substring(0,11).concat("...")}
            </Col>
            <Col xs={3} md={3}>
              {user.role}
            </Col>
            <Col xs={3} md={3}>
              {user.status}
            </Col>
          </Row>
        )
      }
    }
    let shareUrl = "https://www.werewolfremote.com/?referral=played";
    if (false) { // TODO: Implement Win/Lose Logic
      shareUrl = "https://www.werewolfremote.com/?referral=win";
    }
    return (
      <div className={"end-modal"}>
          <Modal.Header closeButton className={"modalHeader"}>
            <Modal.Title id="contained-modal-title-vcenter" className={"col-12 modalTitle"}>
              GAME OVER
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={"modalBody"}>
            <div className={'engGameMessage'}>
              {this.props.isAutoEnd ?
                this.props.isWerewolfVictory ?
                  <p>
                    All Villagers/Special Roles died. Werewolves win!
                  </p>
                  : <p>
                    All Werewolves died. Villagers Win!
                  </p>
                : null}
            </div>
            <Row className={"varRow"}>
              <Col xs={2} md={3}>
                {}
              </Col>
              <Col xs={3} md={3}>
                name
              </Col>
              <Col xs={3} md={3}>
                role
              </Col>
              <Col xs={3} md={3}>
                status
              </Col>
            </Row>
            {modalRows}
          </Modal.Body>
          <Modal.Footer className={"modalFooter"}>
            <FacebookShareButton url={shareUrl}>
              <FacebookIcon size={32} round={false}/>
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl}>
              <TwitterIcon size={32} round={false}/>
            </TwitterShareButton>
            <Button onClick={this.props.routeHome} className={"button"}>Home</Button>
            <Button onClick={this.props.routeStart}
                    className={"button"}
                    style={this.props.userStatus==="host"?{}:{display:"none"}}
                    >
                      Host Again
            </Button>
          </Modal.Footer>
      </div>
    );
  }
}

export default EndModal;
