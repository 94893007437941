import React, { Component } from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import "../AbstainCard/abstain-card.scss";

class GamePlayerCard extends Component {

  render() {
    let voteImages = [];
     
    if (this.props.users) {
      for (let voter of this.props.users) {
        if (voter.ballot && voter.ballot.abstain) {
          voteImages.push(<Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />)
        }
      }
    }

    return (
      <div className={`abstain-card player ${this.props.isSelected ? 'selected' : ''}`} onClick={this.props.onClick} style={{boxShadow: "antiquewhite 0px 0px 5px, antiquewhite 0px 0px 3px, antiquewhite 0px 0px 3px", marginBottom: "8px"}}>
        <Container>
          <Row style={{height:"30px"}}>
            <Col style={{textAlign:"center"}}><i className="fa fa-ban fa-fw"></i>ABSTAIN</Col>
          </Row>
          <Row className={"pl-3"} style={{height:"50px"}}>
            {voteImages}
          </Row>
        </Container>
      </div>
    )
  }
}

export default GamePlayerCard;
