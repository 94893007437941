import {Badge, Col, Container, Dropdown, Image, Row,  OverlayTrigger, Tooltip} from "react-bootstrap";
import React, { Component } from "react";
import '../HostPlayerCard/host-player-card.scss';
import * as Constants from "../Landing/roleConstants";
import antidoteIcon from '../../images/magic.png';
import poisonIcon from '../../images/toxic.png';


class HostPlayerCard extends Component {

  getRoleDetails = (roleSetting, roleName) => {
    let role = roleSetting[roleName.toLowerCase()];
    let side = role.side[0].toUpperCase() + role.side.slice(1);
    return side + "'s Team: " + role.details;
  }

  render() {
    let voteImages = [];
    let user = this.props.user;
    let name = user.name;
    let img = user.picture;
    let role = user.role;
    let status = user.status;
    let votes = user.votes;
    let id = user._id;

    if (votes) {
      for (let vote of votes) {
        if (!vote.abstain) {
          let voter = this.props.getUserById(vote.voter);

          if (this.props.alphawerewolfId.includes(vote.voter) && this.props.currentState === 0) {
            voteImages.push(
            <>
            <Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />
            <Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />
            </>
            )
          } else {
            voteImages.push(<Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />)
          }
        }
      }
    }

    return (
      <div className={`${status === "Alive" ? "player" : "player dead"} ${this.props.statusChanged.includes(id)? "status-changed" : ""}`} style={{boxShadow: "0px 0px 5px antiquewhite, 0px 0px 3px antiquewhite, 0px 0px 3px antiquewhite", marginBottom:"8px"}}>
        <Container style={{paddingBottom: "10px"}}>
          <Row>
            <Col xs={"auto"} className={"text-center"}>
              <Image className={"mt-2 player-image"} src={img} roundedCircle width={"65"} height={"65"}/>
              <Dropdown size={"xs"} style={this.props.isReviewStatusState ? {}:{display:"none"}}>
                <Dropdown.Toggle variant="custom" style={{color:"#867E6E"}}>
                <span style={status === "Alive"? {color:"darkgreen"}:{color:"darkred"}}>{status}</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.props.onAlive} style={{color:"#867E6E"}}>Alive</Dropdown.Item>
                  <Dropdown.Item onClick={this.props.onDead} style={{color:"#867E6E"}} >Dead</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={"auto"}>
              <Row>
                <Col xs={"auto"} style={{marginTop:"9px"}}>

                  <Badge variant="dark" className={"mr-1"}>{this.props.playerNumber}</Badge>
                  <span className={"player__name "} style={{fontSize:"1.1em"}}>{name.length >= 10? name.substring(0,9).concat("..."): name}</span>
                  <div style={{marginTop:"7px"}}>
                    <Badge variant="secondary" style={{textTransform: 'uppercase'}}>{role === "alphawerewolf"? "alpha werewolf": role}</Badge>
                    <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            {this.getRoleDetails(this.props.rolesDetails, role)}
                          </Tooltip>
                        }
                      >
                        <sup><i className="fa fa-question-circle"></i></sup>
                    </OverlayTrigger>
                    <Image className={'tools-icon'} src={antidoteIcon} style={role === 'witch' && this.props.getWitch().antidote && this.props.antidote? {}:{display:'none'}}/>
                    <Image className={'tools-icon'} src={poisonIcon} style={role === 'witch' && this.props.getWitch().poison && this.props.poison? {}:{display:'none'}}/>
                  </div>

                </Col>
              </Row>
              <Row className={"pl-3"} style={{fontSize:"12px", marginTop:"4px"}}>
                {voteImages}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

}

export default HostPlayerCard;
