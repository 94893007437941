import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './confirm.scss';
import {Button, FormControl, InputGroup} from "react-bootstrap";

const Confirm = (props) => {
  return(
      <div className={"confirm-wrapper"}>
          <div className="clouds2"></div>
          <div className={"host-confirm"}>
              {/* <h1 className={"mb-5"}>Werewolf</h1> */}
              
              <h1><span style={{color:"antiquewhite"}} id={"we"}>We</span><span style={{color:"antiquewhite"}} id={"re"}>re</span><span style={{color:"#867E6E"}} id={"wolf"}>wolf</span><span id={"remote"}>Remote</span></h1>


              <h3>Share this link to your players</h3>

              <InputGroup className="mb-3 pl-5 pr-5">
                  <FormControl
                      aria-label="Room URL"
                      aria-describedby="room-url"
                      value={props.roomURL || "www.charlie-is-not-as-handsome-as-jack.com"}
                  />
                  <InputGroup.Append>
                      <CopyToClipboard text={props.roomURL}>
                      <Button variant={"secondary"}><i className="fa fa-clipboard"></i></Button>
                      </CopyToClipboard>
                  </InputGroup.Append>
              </InputGroup>
              <Button className={`mt-5 ${props.roomURL === "Loading Room..."? "d-none" : ""}`} variant={"light"} onClick={props.startAsHost} disabled={props.roomStarted} >START AS HOST</Button>

          </div>
      </div>

  )
}

export default Confirm;
