import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import './landing.scss';
import './guide.scss';
import Button from "react-bootstrap/Button";
import {FormControl, InputGroup, Modal} from "react-bootstrap";
import $ from "jquery";
import RoleCard from "../RoleCard/role-card";
import {Helmet} from "react-helmet";
import { HashLink } from 'react-router-hash-link';
import fbImage from '../../images/social/facebook_1200x627.png';
import twitterImage from '../../images/social/twitter_440x220.png';
import zoomMeeting from'../../images/zoom-meeting.jpeg';
import playerImage from'../../images/tutorial/werewolf.gif';

import {FacebookShareButton, WhatsappShareButton, RedditShareButton, TwitterShareButton, TelegramShareButton} from "react-share";

import * as Constants from "./roleConstants";

class Landing extends Component {

  constructor(props){
    super(props);
    this.state = {show: false};
    this.urlParams = new URLSearchParams(window.location.search);
  }

  componentDidMount() {
    document.getElementById('root').style.filter = 'none';
    var social_open = false
    const menu = document.querySelector('.social-button');
    var menu_point = document.querySelectorAll(".social-point");
    if (menu) {
      menu.addEventListener('click', () => {
        const icon = document.querySelector('#social-icon');
        if(social_open === true) {
          social_open = false;

          menu.title = menu.title.replace(/hide/,"expand")
          menu.classList.remove('social-button-open')
          icon.classList.remove('fa-times')
          icon.classList.add('fa-share-alt')

          for (let i = 0; i < menu_point.length; i++) {
              menu_point[i].classList.remove('social-point-open');
            setTimeout(function() {
              menu_point[i].hidden = true;
            }, 800)
          }
        } else {
          social_open = true;

          menu.title = menu.title.replace(/expand/,"hide")
          menu.classList.add('social-button-open');
          icon.classList.remove('fa-share-alt')
          icon.classList.add('fa-times')

          for (let i = 0; i < menu_point.length; i++) {
            menu_point[i].hidden = false;
            setTimeout(function() {
              menu_point[i].classList.add('social-point-open');
            }, 200)
          }
        }
      });
    }

    let mq = window.matchMedia( "(max-width: 700px)" );
    if ($(window).resize() || mq.matches) {
      $(window).scroll(function() {
        let scrolledY = $(window).scrollTop();
        $('.guide').css('background-position', 'center ' + ((scrolledY)*.8) + 'px');
      });
    }

  }

  handleClick = () => {
    this.props.history.push("/start");
  }

  handleClose = () => {
    document.getElementById('root').style.filter = 'none';
    this.setState(this.setState({show: false}));
  }

  handleShow = () => {
    document.getElementById('root').style.filter = 'blur(2px)';
    this.setState(this.setState({show: true}));
  }

  joinRoom = (event) => {
    this.props.history.push(`/game/${this.state.roomId}`);
  }

  sendEmail = () => {
    window.location.href = `mailto:team@werewolfremote.com`;
  }

  render() {
    let roleCards = []
    for (let role of Constants.roles) {
      roleCards.push(<RoleCard role={role}/>)
    }
    let customMessage = '';
    if (this.urlParams.get('referral')) {
      if (this.urlParams.get('referral') === 'win') {
        customMessage = 'I just had an epic victory playing Werewolf Remote! Come join us!'
      } else {
        customMessage = 'I just played a really fun game called Werewolf Remote! Come join us!'
      }
    }
    let ogUrl = "https://www.werewolfremote.com"
    if (this.urlParams.get('referral')) {
      ogUrl += "/?referral=" + this.urlParams.get('referral');
    }
    return (
    <>
    <div className={"homepage"}>
      <Helmet>
        <title>Werewolf Remote</title>
        <link rel="canonical" href="https://www.werewolfremote.com" />
        <meta name="keywords" content="play werewolf on zoom, werewolf for zoom, mafia for zoom, werewolf remote player, werewolf dashboard, werewolf game" />
        <meta name="description"
              content="A World's first and best tool designed for remote werewolf games. Mafia / Werewolf for Zoom, Hangouts, Facebook Messengers, Google Meet. " />
        <meta property="og:title" content={customMessage || "Werewolf Remote for Zoom and Google Meet"} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:image" content={"https://www.werewolfremote.com" + fbImage} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:description"
              content="A World's first and best tool designed for remote werewolf games. Mafia / Werewolf for Zoom, Hangouts, Facebook Messengers, Google Meet. " />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@WerewolfRemote" />
        <meta name="twitter:creator" content="@WerewolfRemote" />
        <meta name="twitter:title" content={customMessage || "Werewolf Remote for Zoom and Google Meet"} />
        <meta name="twitter:description" content="A World's first and best tool designed for remote werewolf games. Mafia / Werewolf for Zoom, Hangouts, Facebook Messengers, Google Meet. " />
        <meta name="twitter:image" content={"https://www.werewolfremote.com" + twitterImage} />
      </Helmet>
      <div className={"landing"}>
      <nav className={"navbar"}>
        <HashLink to="#guide" className="about">About</HashLink>
        <HashLink to="/tutorial" className="demo">Tutorial</HashLink>
        <HashLink to="/team" className="team">Team</HashLink>
        <HashLink to="#footer" className="contact">Contact</HashLink>
      </nav>
        <div className={"landing__container"}>
          <h1><span style={{color:"antiquewhite"}} id={"we"}>We</span><span style={{color:"antiquewhite"}} id={"re"}>re</span><span style={{color:"#867E6E"}} id={"wolf"}>wolf</span><span id={"remote"}>Remote</span></h1>
          <p>A World's first and best tool designed for remote werewolf games.</p>
          <p>Free · Easy · Fun.</p>
          <p className={"creator_name"}>by AY, CS, JL, and AN.</p>
          <div className={"button-group"}>
            <Button variant="light" onClick={this.handleClick}>MODERATOR</Button>
            <Button variant="dark" onClick={this.handleShow}>JOIN A ROOM</Button>
            <Modal size={"sm"} show={this.state.show} onHide={this.handleClose} centered className={"landing-modal"}>
              <Modal.Header closeButton className={"modalHeader"}>
                <Modal.Title className={"modalTitle"}>Join A Room</Modal.Title>
              </Modal.Header>
              <Modal.Body className={"modalBody"} style={{border:"none"}}>
                <InputGroup className="mb-3">
                  <FormControl
                    placeholder="Room Number"
                    aria-label="Room Number"
                    aria-describedby="basic-addon2"
                    onChange={(event) => this.setState({roomId: event.target.value})}
                  />
                  <InputGroup.Append>
                    <Button variant="outline-secondary" className={"button"} onClick={this.joinRoom} disabled={!this.state.roomId}>Join</Button>
                  </InputGroup.Append>
                </InputGroup>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <div className="social-button" title="expand social media options">
          <i className="fa fas fa-share-alt" id="social-icon" aria-hidden="true"></i>
          <WhatsappShareButton url={ogUrl} className={"social-point"}>
            <i className="fa fab fa-whatsapp"></i>
          </WhatsappShareButton>
          <RedditShareButton url={ogUrl} className={"social-point"}>
            <i className="fa fas fa-reddit"></i>
          </RedditShareButton>
          <TwitterShareButton url={ogUrl} className={"social-point"}>
            <i className="fa fab fa-twitter"></i>
          </TwitterShareButton>
          <TelegramShareButton url={ogUrl} className={"social-point"}>
            <i className="fa fab fa-telegram" aria-hidden="true"></i>
          </TelegramShareButton>
          <FacebookShareButton url={ogUrl} className={"social-point"}>
            <i className="fa fab fa-facebook-square" aria-hidden="true" ></i>
          </FacebookShareButton>

        </div>
        <div className="buymeacoffee-button">
          <link href="https://fonts.googleapis.com/css?family=Cookie" rel="stylesheet"></link>
          <a className="bmc-button" target="_blank" rel="noopener noreferrer" href="https://www.buymeacoffee.com/werewolfremote">
            <img src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" alt="Buy me a coffee"></img>
            <span>Buy me a coffee</span>
          </a>
        </div>
        <div className="clouds"></div>
      </div>
      

      <div className={"circle-black"}></div>
      <div className={"guide"} id={"guide"}></div>
        <div className={"guide__container"}>
        <div class="row"> 
          <div class="cell">
            <img src={zoomMeeting} style={{width:"190px", borderRadius: "8px", border: "1px solid antiquewhite"}}/>
          </div>
          <div class="cell">
            <img src={playerImage} style={{width:"200px", borderRadius: "8px", border: "1px solid antiquewhite"}}/>
          </div>
        </div>
          <div className={"guide__container__intro"}>
            <h2 className={"heading"}>WEREWOLF REMOTE</h2>
            <p className={"content"}>
              Werewolf is a game that is best played with a host and 5+ players who get randomly selected roles unknown to each other.
            </p>
            <p className={"content"}>
              Every night when everyone is asleep, werewolves get to slaughter some humans. Once awake, all remaining players get to vote out a potential werewolf through discussion.
            </p>
            <p className={"content"}>
              The goal is to eliminate all werewolves before humankind goes extinct.
            </p>
          </div>
          <div className={"guide__container__howto"}>
          <h2 className={"heading"}>HOW TO PLAY</h2>
          <ol>
            <li>Connect via any meeting tool (Zoom, Goolge Meet, Skype etc.) </li>
            <li>The moderator picks a desired role setting, then opens a new room. </li>
            <li>After getting the host’s URL, players submit their names and pictures. Each player will be randomly assigned a role from the selected setting. </li>
            <li>In the room, the host can update players’ life status. The players can vote for the person to be removed when the host enables the poll. <HashLink to="/tutorial" className="demo" style={{color:"navajowhite"}}>(More)</HashLink></li>
          </ol>
          </div>
          <div className={"guide__container__roles"}>
            <h2 className={"heading"}>ROLES</h2>
            <div className={"guide__container__roles__carousel"}>
              {roleCards}
            </div>
          </div>
        </div>
        <div className={"footer"} id={"footer"}>
          <p className={"email"} onClick={this.sendEmail}>moc.etomerflowerew@maet</p>
          <p className={"terms"}>© 2022 AY CS JL AN. All rights reserved.</p>
          <p>
            <a href="/terms" className={"terms"}><span>Terms</span></a>
            <a href="/privacy" className={"terms"}><span>Privacy</span></a>
          </p>
        </div>
    </div>
    </>
    );
  };
};

export default withRouter(Landing);
