import React from 'react';
import { withRouter } from 'react-router-dom';
import "../InvalidLink/invalid-link.scss";

class InvalidLink extends React.Component {

  componentDidMount() {
    document.getElementById('root').style.filter = 'none';
  }

  routeHome = () => {
    this.props.history.push("/");
  }

  render() {
    return (
      <>
      <div className="blurred-bg"></div>
      <div className="center-text">
        <h3>INVALID LINK</h3>
        <p onClick={this.routeHome}>Return Home</p>
      </div>
      </>
    )
  }
}
export default withRouter(InvalidLink);