import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import "../Team/team.scss";
import jackImg from'../../images/jack.png';
import charlieImg from'../../images/charlie.png';
import alvinImg from'../../images/alvin.png';
import alanImg from'../../images/alan.png';
import jeffreyImg from'../../images/jeffrey.png';


class Team extends React.Component {

  componentDidMount() {
    document.getElementById('root').style.filter = 'none';
  }

  routeHome = () => {
    this.props.history.push("/");
  }

  sendEmail = () => {
    window.location.href = `mailto:team@werewolfremote.com`;
  }

  render() {
    return (
      <div class="team_wrapper">
        <nav className={"navbar"}>
          <Link to="/" className="home">Home</Link>
        </nav>
        <div class="stars"></div>
        <div class="twinkling"></div>
        <h1 className="team_title">MEET OUR TEAM</h1>
        <div className="team_email_wrapper">
          <p className="team_email" onClick={this.sendEmail}>moc.etomerflowerew@maet</p>
        </div>
        <div class="team">
          <div class="team_member">
            <div class="team_img">
              <img src={alvinImg} alt="Team_image"/>
            </div>
            <h3>Alvin Yau</h3>
            <p class="role">Founding Engineer</p>
            <p className="member_description">Alvin specializes in UX/UI design. Before founding Werewolf Remote, he served as a Software Engineer for Tesla and Norton by Symantec. Alvin holds a Bachelor's degree in Computer Science from UC Berkeley.</p>
          </div>
          <div class="team_member">
            <div class="team_img">
            <img src={charlieImg} alt="Team_image"/>
            </div>
            <h3>Charlie Siu</h3>
            <p class="role">Founding Engineer</p>
            <p className="member_description">Charlie holds a Master's degree in Computer Science and Engineering from Santa Clara University and a Bachelor's degree in Computer Science from UC Berkeley.</p>
          </div>
          <div class="team_member">
            <div class="team_img">
              <img src={jackImg} alt="Team_image"/>
            </div>
            <h3>Jack Lok</h3>
            <p class="role">Founding Engineer</p>
            <p className="member_description">Jack specializes in frontend development. He worked in the autonomous vehicle industry and e-commerce industry. Jack holds a M.S. from Columbia University and a B.A. from UC Berkeley.</p>
          </div>
          <div class="team_member">
            <div class="team_img">
              <img src={alanImg} alt="Team_image"/>
            </div>
            <h3>Alan Ng</h3>
            <p class="role">Full-Stack Engineer</p>
            <p className="member_description">Alan has 8 years working experience in data analytics, robotic process automation, and software development fields. He holds a Bachelor's degree in Statistics from UC Berkeley.</p>
          </div>
          <div class="team_member">
            <div class="team_img">
              <img src={jeffreyImg} alt="Team_image"/>
            </div>
            <h3>Jeff Chau</h3>
            <p class="role">Graphic Designer</p>
            <p className="member_description">Jeff works on role cards design. He is pursuing a Master's degree in Mechanical Engineering and holds a Bachelor's degree from San Jose State University.</p>
          </div>
        </div>

      </div>
    )
  }
}
export default withRouter(Team);
