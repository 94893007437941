import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Landing from '../components/Landing/landing';
import Terms from '../components/Terms/terms';
import Tutorial from '../components/Demo/tutorial';
import Privacy from '../components/Privacy/privacy';
import Team from '../components/Team/team';
import Start from '../components/Start/start';
import Game from '../components/Game/game';
import InvalidLink from '../components/InvalidLink/invalid-link';


export default () => (
  <Switch>
      <Route exact path='/' component={ Landing }/>
      <Route path='/terms' component={ Terms }/>
      <Route path='/privacy' component={ Privacy }/>
      <Route path='/start' component={ Start }/>
      <Route path='/team' component={ Team }/>
      <Route path='/tutorial' component={ Tutorial }/>
      <Route path='/game/:roomId' component={ Game }/>
      <Route path="*" component={ InvalidLink }/>
  </Switch>
);

