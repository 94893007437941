import HunterImage from '../../images/roles/hunter.png';
import KnightImage from '../../images/roles/knight.png';
import VillagerImage from '../../images/roles/villager.png';
import WerewolfImage from '../../images/roles/werewolf.png';
import AlphawerewolfImage from '../../images/roles/alphawerewolf.png'; 
// import DoctorImage from '../../images/roles/doctor.png'; 
import SeerImage from '../../images/roles/seer.png'; 
import WitchImage from '../../images/roles/witch.png'; 

const seer = {
  name: 'Seer',
  side: 'villager',
  thumbnail: SeerImage,
  details: 'Each night, point at a player and learn if they are: Either on the villager team, or the werewolf team.'
}

const hunter = {
  name: 'Hunter',
  side: 'villager',
  thumbnail: HunterImage,
  details: 'If you are killed, take someone down with you.'
}

const witch = {
  name: 'Witch',
  side: 'villager',
  thumbnail: WitchImage,
  details: 'Kill or heal a player, once each per game.'
}

const werewolf = {
  name: 'Werewolf',
  side: 'werewolf',
  thumbnail: WerewolfImage,
  details: 'Kill a villager each night.'
}

const alphaWerewolf = {
  name: 'Alpha Werewolf',
  side: 'werewolf',
  thumbnail: AlphawerewolfImage,
  details: 'When the Alpha Werewolf votes on a player to kill during the night, his vote counts twice.'
}

const villager = {
  name: 'Villager',
  side: 'villager',
  thumbnail: VillagerImage,
  details: 'You sleep in the Night phase.'
}

// const doctor = {
//   name: 'Doctor',
//   side: 'villager',
//   thumbnail: DoctorImage,
//   details: 'Select one player to heal each night. If this player is attacked by the Werewolves, they don\'t die that night. The Doctor cannot heal themselves.'
// }

const knight = {
  name: 'Knight',
  side: 'villager',
  thumbnail: KnightImage,
  details: 'Once per game, he can duel with a player. If the player is a werewolf, he dies and the day ends immediately. But if the player is a villager, the knight commits suicide and day continues.'
}

// export const roles = [werewolf, alphaWerewolf, villager, seer, hunter, witch, doctor, knight];
export const roles = [werewolf, alphaWerewolf, villager, seer, hunter, witch, knight];
