import React, { Component } from "react";
import './player-card-empty.scss';

class PlayerCardEmpty extends Component {

  render() {
    return (
      <div className={"player dead"}>
          <div className={"empty"}>
            <p>WAITING</p>
          </div>
      </div>
    )
  }

}

export default PlayerCardEmpty;