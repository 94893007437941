import React, {Component} from 'react';
import './tutorial.scss';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import hostGameSetting from'../../images/tutorial/host-game-setting.gif';
import playerJoin from'../../images/tutorial/player-join.png';
import werewolf from'../../images/tutorial/werewolf.gif';
import werewolfHost from'../../images/tutorial/werewolf-host.gif';
import seer from'../../images/tutorial/seer.gif';
import seerHost from'../../images/tutorial/seer-host.gif';
import witch from'../../images/tutorial/witch.gif';
import witchHost from'../../images/tutorial/witch-host.gif';
import reviewHost from'../../images/tutorial/review-host.gif';
import wakeUpHost from'../../images/tutorial/wake-up-host.gif';
import wakeUp from'../../images/tutorial/wake-up.gif';
import afterTrialHost from'../../images/tutorial/after-trial-host.gif';
import afterTrial from'../../images/tutorial/after-trial.gif';
import sleeping from'../../images/tutorial/sleeping.jpeg';

class Tutorial extends Component {
  render() {
    const settings = {
      infinite: false,
      arrows: false,
      dots: true,
      autoplay: false,
      speed: 1100,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }],
    };
    return (
      <div className={"demo"}>
        <div className={"demo-container"}>

          <nav className={"navbar"}>
            <Link to="/" className="home">Home</Link>
          </nav>

          <h2><strong>Tutorial</strong></h2>

          <Slider className={"timeline-carousel"} {...settings}>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${hostGameSetting}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <span class="year">Start</span>
                <span class="month">Host</span>
                <p>Configure the character settings and share the link to your fellow players. </p>
                {/*{/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${playerJoin}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <div class="pointer"></div>
                <span class="month">Player</span>
                <p>Join the room via the host's link. Custom your name and upload an avatar to join the game. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${werewolfHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <span class="year">Night Begins</span>
                <span class="month">Host</span>
                <p>Click the "Start Night" button to begin the night. Werewolf players start voting to select a victim to murder. Once all werewolves come to an agreement, proceed to seer. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${werewolf}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <div class="pointer"></div>
                <span class="month">Player</span>
                <p>Coordinate other werewolves to murder exactly one victim. As the leader of the pack, the alphawolf has a vote that counts twice as much.	</p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${seerHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <span class="year">Seer</span>
                <span class="month">Host</span>
                <p>Wait for seer to check on another player's side. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div class="timeline-carousel__item">
              <div class="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${seer}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div class="timeline-carousel__item-inner">
                <div class="pointer"></div>
                <span class="month">Player	</span>
                <p>Reveal any one player to see which side s/he is on.	</p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${witchHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <span className="year">Witch</span>
                <span className="month">Host</span>
                <p>There are two rounds for the witch character: antidote and poison. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${witch}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <div className="pointer"></div>
                <span className="month">Player	</span>
                <p>Witch is notified which player is murdered by the werewolves. The witch has exactly one antidote to heal a player killed by the werewolf at this round and one poison to kill a potential werewolf. Cast votes to indicate the player to heal / poison. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${reviewHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <span className="year">Review</span>
                <span className="month">Host</span>
                <p>Review the life status of each player and make adjustment if needed. Proceed after review and the system would automatically end the game if there is a winner. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${sleeping}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <div className="pointer"></div>
                <span className="month">Player	</span>
                <p>Everyone is sleeping. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${wakeUpHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <span className="year">Sunrise and Open Trial</span>
                <span className="month">Host</span>
                <p>Another day has begun and all players are notified with their live status. Players that are dead at night have a chance to give their final words for the open trial. All remaining players get to vote out a potential werewolf. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${wakeUp}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <div className="pointer"></div>
                <span className="month">Player	</span>
                <p>Players that are dead in this round are indicated with blinking. Discuss among all remaining players and vote out a potential werewolf with a majority. Can't make up your mind? You can abstain the vote. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${afterTrialHost}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <span className="year">Public Execution</span>
                <span className="month">Host</span>
                <p>Status of the player with the highest number of votes can be changed to death. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

            <div className="timeline-carousel__item">
              <div className="timeline-carousel__image">
                <div class="media-wrapper media-wrapper--overlay" style={{background: `url(${afterTrial}) center center`, backgroundSize:"cover"}}></div>
              </div>
              <div className="timeline-carousel__item-inner">
                <div className="pointer"></div>
                <span className="month">Player	</span>
                <p>After the execution, the player with the highest number of votes can express the final words. Other players can contemplate their actions. If all werewolves are eliminated or other end game conditions are met, the game will end. </p>
                {/*<a href="#" className="read-more">Read more</a>*/}
              </div>
            </div>

          </Slider>
        </div>
      </div>
    );
  }
}

export default Tutorial;
