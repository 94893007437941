import React from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import Routes from './config/routes';
import ReactGA from 'react-ga';
import NoSleep from 'nosleep.js';

function App() {
  ReactGA.initialize('UA-170111364-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  var noSleep = new NoSleep();
  noSleep.enable();
  return (
    <Routes  />
  );
}

export default withRouter(App);
