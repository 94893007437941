import React  from 'react';
import './game-preplayer.scss';
import {Button, Image} from "react-bootstrap";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import axios from 'axios';

import { Icon } from '@iconify/react';
import shutterIcon from '@iconify/icons-gridicons/shutter';
import cameraIcon from '@iconify/icons-entypo/camera';
import io from "socket.io-client";
import ReactGA from "react-ga";
const defaultImg = require('../../images/upload-img.jpg');

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dppa2zk3w/upload';
const CLOUDINARY_UPLOAD_PRESET = 'lhsrrwex'

class GamePreplayer extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        name: "",
        file: defaultImg,
        fileToUpload: "",
        fileSize: "",
        fileSizeSuccess: true,
        cameraOn: false,
        selfieMode: false,
        roomId: window.location.href.split("/").pop(),
      }
      this.nameChange = this.nameChange.bind(this);
      this.imgChange = this.imgChange.bind(this);
      this.imgContainerClick = this.imgContainerClick.bind(this);
      this.turnOnCamera = this.turnOnCamera.bind(this);
      this.joinRoom = this.joinRoom.bind(this);
      this.socket = io.connect('/' + this.state.roomId);
  }

  // Function to handle name change
  nameChange(event) {
    event.preventDefault();
    this.setState({
      name: event.target.value
    })
  }

  // Function to handle image change
  imgChange(event) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      this.setState({fileToUpload: event.target.files[0], fileSize: event.target.files[0].size})
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({file: e.target.result});
      };
      reader.readAsDataURL(event.target.files[0]);
      this.setState({fileSizeSuccess: event.target.files[0].size <= 8000000})
    }
  }

  // Function to trigger hidden image upload input
  imgContainerClick(event) {
    event.preventDefault();
    document.getElementById('file').click();
  }

  // Function to post player's info and refresh from pre-player to player
  joinRoom(event) {
    event.preventDefault();
    console.log(`Player's info submitted.`)
    document.getElementById('join-game-btn').setAttribute("disabled","true");
    document.getElementById('nameInput').setAttribute("disabled","true");
    document.getElementById('file').disabled = true;
    document.getElementById('image-btn').style.cursor = "wait";
    document.getElementById('camera-btn').style.display = "none";
    document.getElementById('join-game-btn').innerHTML = "JOINING";

    if (this.state.fileToUpload !== "") {
      // Upload image to Cloudinary
      let formData = new FormData();
      formData.append('file', this.state.fileToUpload);
      formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
      axios({
        url: CLOUDINARY_URL,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-url-urlencoded'
        },
        data: formData
      }).then(res => {
        this.setState({fileToUpload: res.data.secure_url})
        this.submitPlayerProfile()
        .then(res => {
          this.socket.emit('player join', 'refresh getRoom', (data) => window.location.reload());
        })
        .catch(err => {
          this.setState({err})
          console.log(err)
        })
      }).catch(err => {
        this.setState({err})
        console.log(err)
      })
    } else {
      this.submitPlayerProfile()
      .then(res => {
        ReactGA.event({
          category: 'User',
          action: 'Joined as a Player'
        });
        this.socket.emit('player join', 'refresh getRoom', (data) => window.location.reload());
      })
      .catch(err => {
        ReactGA.event({
          category: 'Room',
          action: 'Room is Full'
        });
        this.setState({err})
        console.log(err)
      })
    }
  }

  submitPlayerProfile() {
    const DEFAULT_PROFILE_PIC_URL = 'https://res.cloudinary.com/dppa2zk3w/image/upload/v1592435477/default_profile_pic.jpg';
    return axios.post(`/rooms/${this.state.roomId}/users`, {
      name: this.state.name,
      picture: this.state.fileToUpload ? 'https://res.cloudinary.com/dppa2zk3w/image/upload/c_fill,h_520,q_100,r_max,w_520/' + this.state.fileToUpload.substring(50) : DEFAULT_PROFILE_PIC_URL,
    })
  }

  turnOnCamera(event) {
    event.preventDefault();
    this.setState({
      cameraOn: true,
      selfieMode: true,
    })
  }

  triggerSelfie(event) {
    event.preventDefault();
    document.getElementById('inner-circle').click();
  }

  handleTakePhoto(dataUri) {

    if (dataUri !== "data:,") {
      this.setState({
        file: dataUri || defaultImg,
        fileToUpload: dataUri,
        fileSize: dataUri.length,
        fileSizeSuccess: dataUri.length <= 8000000,
        cameraOn: false,
        selfieMode: false,
      })
    } else { //handle camera error
      this.setState({
        cameraOn: false,
        selfieMode: false,
      })
    }
  }

  render() {
      return (
        <div className="preplayer-wrapper">
        {/* <h1>Werewolf</h1> */}
        <div className="clouds2"></div>
        <h1><span style={{color:"antiquewhite"}} id={"we"}>We</span><span style={{color:"antiquewhite"}} id={"re"}>re</span><span style={{color:"#867E6E"}} id={"wolf"}>wolf</span><span id={"remote"}>Remote</span></h1>
        <div className={"preplayer"}>
          <div className={"preplayer__container"}>
            <div className={"preplayer__room"}>
              <span>Room # : {this.state.roomId}</span>
            </div>
            <div className="form-group preplayer__nameInput">
              <label htmlFor="formGroupExampleInput">Your Name:</label>
              <input
                type="text"
                className="form-control"
                id="nameInput"
                onChange={this.nameChange}
                maxlength="12"
              />
            </div>
            <div className={"preplayer__image-container"}>
              <Image id={"image-btn"} className={"mt-5 player-image"} style={{cursor:'pointer'}}  onClick={this.imgContainerClick} src={this.state.file} roundedCircle />
              <p id={"size-message"} class={"size-success"} style={(this.state.file !== defaultImg && this.state.fileSizeSuccess === true)?{}:{display:"none"}}>Success!</p>
              <p id={"size-message"} class={"size-warning"} style={this.state.fileSizeSuccess === false?{}:{display:"none"}}>The attachment size exceeds the allowable limit.</p>
              <input id="file" type="file" style={{display:'none'}} onChange={this.imgChange}/>
              <div className={"preplayer__selfie d-none d-md-block"}>
                <Button id={"camera-btn"} style={!this.state.cameraOn? {}: {display:"none"}} className={"mr-1"} variant="light" onClick={this.turnOnCamera}><Icon icon={cameraIcon} width="22" height="22" /></Button>
                <Button style={this.state.cameraOn? {}: {display:"none"}} className={"mr-1"} variant="light" onClick={this.triggerSelfie}><Icon icon={shutterIcon} width="22" height="22" /></Button>
              </div>
            </div>

            <div id="takeshot" className={this.state.selfieMode ? 'active' : 'hidden'}>
              <Camera
              style={{width:10}}
              sizeFactor = {1}
              onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
            />
            </div>
            <div className={"preplayer__join"}>
              <Button id={"join-game-btn"} className={"mr-1"} variant="light" onClick={this.joinRoom} disabled={!/\S/.test(this.state.name) || !this.state.fileSizeSuccess}>JOIN GAME</Button>
              {this.state.err ? <p className={"error"}>Room is Full</p> : ''}
            </div>
            <div>
              <p>For the best experience, leave your screen on during the game session.</p>
            </div>
          </div>
        </div>
      </div>
      );
  }
}

export default GamePreplayer;
