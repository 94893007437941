import React from 'react';
import { withRouter } from 'react-router-dom';
import GameHost from '../GameHost/game-host';
import GamePreplayer from '../GamePreplayer/game-preplayer';
import GamePlayer from '../GamePlayer/game-player';
import InvalidRoom from '../InvalidRoom/invalid-room';
import axios from 'axios';
import HostPlayerCard from '../HostPlayerCard/host-player-card'

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userStatus: "",  // host/preplayer/player
    };
    const roomId = window.location.href.split("/").pop();
    this.getRoleDetails(roomId);
  }

  componentDidMount() {
    document.getElementById('root').style.filter = 'none';
    // GET request to determine host/preplaer/player
    const roomId = window.location.href.split("/").pop();
    axios.get(`/rooms/${roomId}/userstatus`)
    .then(res => {
      this.setState({userStatus: res.data.userStatus, name: res.data.name, user: res.data.user})
    })
    .catch(error => {
      console.log(error , "invalid-room")
      this.setState({userStatus: "invalid-room"})
    })
  }

  getRoleDetails(roomId) {
    axios.get(`/rooms/${roomId}`)
    .then(res => {
      const rolesDetails = res.data.roleSetting
      this.setState({
        rolesDetails: rolesDetails,
      });
    })
    .catch(error => {
      console.log("cannot get role details: ", error)
    })
  }

  render() {
    let statusSwitcher = (
      <>
        <button className={"debug-mode"} onClick={()=> {this.setState({userStatus: "host"})}}>Host Mode</button>
        <button className={"debug-mode"} onClick={()=> {this.setState({userStatus: "preplayer"})}}>PrePlayer Mode</button>
        <button className={"debug-mode"} onClick={()=> {this.setState({userStatus: "player"})}}>Player Mode</button>
        <button className={"debug-mode"} onClick={() => document.body.classList.toggle('debug')}>Debug on/off</button>
      </>
  );
    const status = this.state.userStatus;
    if (status === "host") {
      return (
        <>
          <GameHost userStatus={this.state.userStatus}/>
          {statusSwitcher}
        </>
      )
    } else if (status === "preplayer") {
        return (
          <>
            <GamePreplayer />
            {statusSwitcher}
          </>
        )
    } else if (status === "player") {
        return (
          <>
            <GamePlayer userStatus={this.state.userStatus}
                        name={this.state.name}
                        user={this.state.user}
                        rolesDetails = {this.state.rolesDetails}/>
            {statusSwitcher}
          </>
        )
    } else if (status === "invalid-room") {
        return (
          <>
          <InvalidRoom/>
          </>
        )
    } else {
        return (
          <div style={{color:"antiquewhite"}}>Loading Game...</div>
        )
    }
  }
}

export default withRouter(Game);
