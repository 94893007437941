import React from 'react';
import { withRouter } from "react-router-dom";
import './game-host.scss';
import {OverlayTrigger, Popover, Table, Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import HostPlayerCard from "../HostPlayerCard/host-player-card";
import PlayerCardEmpty from "../PlayerCardEmpty/player-card-empty";
import EndModal from "../EndModal/end-modal";
import io from "socket.io-client";
import AbstainCard from '../AbstainCard/abstain-card'

class GameHost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      host: true,
      room: {users:[]},
      roomId: window.location.href.split("/").pop(),
      originalUsers: "",
      statusChanged: [],
      modalShow: false,
      isPollActive: false,
      isModified: false,
      isWerewolfVictory: false,
      isAutoEnd: false,
      currentState: -1,
      displayMessageBoard: false,
      werewolvesVictimMessage: "",
      werewolvesVictim: "",
      antidoteUserId: "",
      antidote: true,
      poison: true,
      poisonUserId: "",
      werewolfPollUserId: "",
      alphawerewolfId: [],
      messageBoard: [],
      currentStateMessage: "",
      isReviewStatusState: false,
      openTrialState: "startPoll", // states: startPoll, endPoll, update
    };
    const roomId = window.location.href.split("/").pop();
    this.socket = io.connect('/' + roomId);
    this.socket.on('update players', (data) => {
      console.log('Host pushed an update to players');
      this.getRoom(roomId);
    })
    this.socket.on('update timeline message', (msg) => {
      console.log('update timeline message: ' + msg)
      this.updateTimelineMessage(msg)
    })
  }

  componentDidMount() {
    const roomId = window.location.href.split("/").pop();
    this.getRoom(roomId);
  }

  /**
   * Get room
   * @param roomId
   */
  getRoom(roomId) {
    axios.get(`/rooms/${roomId}`)
      .then(res => {
        const room = res.data;
        this.setState({isLoaded: true, room, });
      })
      .catch(error => {
        this.setState({isLoaded: true, error});
      })
  }

  /**
   * Update userStatus and check end game conditions
   */
  updateUserStatusAndCheckEndGame() {
    this.setState({isReviewStatusState: false, openTrialState: 'startPoll'});
    this.removeBallots()
    let roomId = this.state.room._id;
    let users = this.state.room.users;

    axios.post(`/rooms/${roomId}/updatePlayers`, {users})
      .then(res => {
        const room = res.data;
        this.setState({
          isLoaded: true,
          isModified: false,
          room,
        }, () => {
          let originalUsers = this.state.originalUsers;
          let updatedUsers = room.users;
          let statusChanged = [];
          for (let i=0; i<originalUsers.length; i++) {
            if (originalUsers[i].status !== updatedUsers[i].status) {
              statusChanged.push(originalUsers[i]._id);
            } 
          }
          this.setState({statusChanged});
          if (this.fulfilEndGameConditions(room)) {
            this.setState({isAutoEnd: true});
            this.endGame();
          } else {
            this.socket.emit('update players', 'refresh getRoom');
          }
        })
      })
      .catch(error => {
        this.setState({isLoaded: true, error})
      })
  }

  /**
   * Determine if the game has fulfilled the end game conditions
   * @param room
   * @returns {boolean}
   */
  fulfilEndGameConditions (room) {
    let numberOfLiveVillagers = 0;
    let numberOfLiveGodCharacters = 0;
    let numberOfLiveWerewolves = 0;
    room.users
      .filter(user => user.status === 'Alive')
      .forEach(user => {
        if (user.role === 'villager') {
          numberOfLiveVillagers++
        }
        if (user.side === 'villager' && user.role !== 'villager') {
          numberOfLiveGodCharacters++
        }
        if (user.side === 'werewolf') {
          numberOfLiveWerewolves++
        }
      });
    if (numberOfLiveVillagers === 0) this.setState({isWerewolfVictory: true});
    if (numberOfLiveGodCharacters === 0) this.setState({isWerewolfVictory: true});
    if (numberOfLiveWerewolves === 0) this.setState({isWerewolfVictory: false});
    return numberOfLiveVillagers === 0 || numberOfLiveGodCharacters === 0 || numberOfLiveWerewolves === 0
  }

  /**
   * Remove all ballots in the room from the back end and front end.
   */
  removeBallots = () => {
    console.log("Remove all ballots in the room");
    axios.delete(`/rooms/${this.state.roomId}/deleteBallots`)
      .then(res => {
        console.log("All ballots removed");
        this.clearUiVotes();
      })
      .catch(error => {
        console.log("Unable to Remove all ballots");
      })
  };

  /**
   * Clear client side votes only. Called by removeBallots().
   */
  clearUiVotes () {
    let newUsers = this.state.room.users.map(user => {return {...user, votes: [], ballot:[]}});
    this.setState({room: {...this.state.room, users: newUsers}})
  }

  /**
   * Start Poll (UI) for role specific and poll type (e.g. for witch: poison / antidote).
   *
   * @param role, pollType
   */
  startPoll(role = 'all', pollType = '') {
    this.setState({statusChanged: []})
    let alphawerewolfId = [];
    let alphawerewolves = this.state.room.users.filter(user => user.role === 'alpha werewolf');
    if (alphawerewolves) {
      for (let alphawerewolf of alphawerewolves) {
        alphawerewolfId.push(alphawerewolf._id);
      }
    }
    this.setState({alphawerewolfId});
    let data = {
      role: role.toLowerCase(),
      pollType,
      victim: this.state.werewolvesVictim || "",
      alphawerewolfId,
      currentState: this.state.currentState + 1
    };
    this.removeBallots();
    this.socket.emit('poll start', data);
    this.setState({isPollActive: true})
  }

  /**
   * End Poll (UI)
   */
  endPoll() {
    this.clearUiVotes();
    this.socket.emit('poll end', 'poll end');
    this.setState({isPollActive: false})
  }

  /**
   * Start Open Trial
   */
  startOpenTrial() {
    this.startPoll();
    this.setState({openTrialState: 'endPoll', originalUsers: this.state.room.users});
  }

  /**
   * End Open Trial
   */
  endOpenTrial() {
    this.endPoll();
    this.setState({isReviewStatusState: true, openTrialState: 'update'});
  }

  /**
   * Display End Game Modal.
   */
  endGame() {
    this.socket.emit('game end', this.state);
    this.setState({ modalShow: true });
    document.getElementById('root').style.filter = 'blur(5px)';
    axios.delete(`/rooms/${window.location.href.split("/").pop()}`)
  }

  /**
   * Set User Status (Async). Modify specific user in state's room.
   */
  setUserStatus = (userId, status) => {
    const newState = {
      isModified: true,
      room: {
        ...this.state.room,
        users:
          this.state.room.users.map((user) =>
            user._id === userId ? {...user, status: status, } : user
          ),
      }};
    this.setState(newState);
  };

  /**
   * Get user by id in the state.
   */
  getUserById = (userId) => {
    return this.state.room.users.find((user) => user._id === userId)
  };

  /**
   * End Game Modal's functions.
   */
  routeHome = () => {
    document.getElementById('root').style.filter = 'none';
    this.props.history.push("/");
  };

  /**
   * End Game Modal's functions.
   */
  routeStart = () => {
    document.getElementById('root').style.filter = 'none';
    this.props.history.push("/start");
  };

  /**
   * Update the latest message in event message board.
   */
  updateTimelineMessage (msg) {
    if (msg === this.state.messageBoard[this.state.messageBoard.length - 1]) {
      this.state.messageBoard.pop()
    }
    this.setState({ currentStateMessage: msg })
  }

  renderRoleSettings = (roleSetting) => {
    let rendering = [];
    for (let role in roleSetting) {
      let roleItem = roleSetting[role];
      let name = roleItem.name;
      let count = roleItem.quantity;
      if (count > 0) {
        rendering.push(
          <tr>
            <td><strong style={{textTransform: "capitalize"}}>{name}</strong></td>
            <td>{count}</td>
          </tr>)
      }
    }
    return rendering
  };

  getTimeLineStatus = (itemState) => {
    if (itemState >= 2) itemState ++
    if (itemState == this.state.currentState) {
      return "in-progress"
    } else if (itemState < this.state.currentState) {
      return "completed"
    } else {
      return ""
    }
  };

  incrementCurrentState = () => {
    if (this.state.currentStateMessage) this.addMessage(this.state.currentStateMessage)
    this.setState({currentState: this.state.currentState + 1, currentStateMessage: ""});
  };

  resetCurrentState = () => {
    this.setState({
      currentState: -1,
      displayMessageBoard: true,
      werewolfPollUserId: '',
      antidoteUserId: '',
      poisonUserId: '',
      isReviewStatusState: false,
      currentStateMessage: ''
    })
  };

  setWerewolfVote = () => {
    this.setState({originalUsers: this.state.room.users});
    this.resetMessageBoard();
    this.endPoll();
    this.startPoll('werewolf');
    this.incrementCurrentState();
  };

  showWerewolvesVictim = () => {
    let users = this.state.room.users;

    if (users && this.state.currentState === 0) {
      // TODO use 'side' later
      let wolves = users.filter(user => user.status === "Alive" && user.side === "werewolf");
      let killed = [];
      for (let wolve of wolves) {
        killed.push(wolve.ballot && wolve.ballot.candidate);
      }

      if (killed) {
        const allEqual = arr => arr.every( v => v === arr[0]);
        if (killed.length >= wolves.length && allEqual(killed)) {
          let killedId = killed[0];
          let victim = users.filter(user => user._id === killedId)[0];
          if (victim) {
            return ({
              message: `Werewolves killed ${victim.role.charAt(0).toUpperCase() + victim.role.substr(1)} (${victim.name.toUpperCase()}).`,
              victim: victim.name.toUpperCase(),
              victimId: killedId
            })
          }
        }
      }
    }
    return ({
      message: '',
      victim: ''
    })
  };

  setSeerVote = () => {
    // TODO: Set the clear majority of werewolves' votes
    let werewolvesVictimMessage = this.showWerewolvesVictim().message;
    let werewolvesVictim = this.showWerewolvesVictim().victim;
    let werewolfPollUserId = this.showWerewolvesVictim().victimId;
    this.addMessage(werewolvesVictimMessage);
    this.setState({werewolvesVictimMessage, werewolvesVictim, werewolfPollUserId});
    this.endPoll();
    this.startPoll('seer');
    this.incrementCurrentState();
  };

  setWitchAntidoteVote = () => {
    this.endPoll();
    this.startPoll('witch');
    this.incrementCurrentState();
  };

  setWitchPoisonVote = () => {
    // Record Antidote User
    let witchBallot = this.getWitch().ballot;
    if (witchBallot && !witchBallot.abstain) {
      this.setState({antidoteUserId: witchBallot.candidate})
    }
    let antidote = !this.state.currentStateMessage.includes('used antidote')
    this.setState({antidote})
    this.endPoll();
    this.startPoll('witch', 'poison');
    this.incrementCurrentState();
  };

  getWitch = () => {
    return this.state.room.users.filter(user => user.role === 'witch')[0];
  };

  setCustomVote = (role) => {
    this.saveWitchPoison()
    this.endPoll();
    this.startPoll(role);
    this.incrementCurrentState();
  };

  saveWitchPoison (callback) {
    let witchBallot = this.getWitch().ballot
    if (witchBallot && !witchBallot.abstain) {
      let poison = !this.state.currentStateMessage.includes('used poison')
      this.setState({ poison, poisonUserId: witchBallot.candidate }, ()=> {
        if (callback) callback()
      })
    } else {
      if (callback) callback()
    }
  }

  setSunrise = () => {
    this.saveWitchPoison(this.setSunriseCallback)
  };

  setSunriseCallback = () => {
    let users = this.state.room.users;
    let antidote = this.getWitch().antidote;
    let poison = this.getWitch().poison;

    if (this.state.antidoteUserId) {
      antidote = false;
    }
    if (this.state.poisonUserId) {
      poison = false;
    }

    users = users.map(user => user.role === 'witch' ? { ...user, antidote, poison } : user);

    // Werewolf Votes
    if (this.state.werewolfPollUserId) {
      users = users.map(user => user._id === this.state.werewolfPollUserId ? { ...user, status: "Dead" } : user)
    }
    // Witch Antidote
    if (this.state.antidoteUserId) {
      users = users.map(user => user._id === this.state.antidoteUserId ? { ...user, status: "Alive" } : user)
    }
    // With Poison
    if (this.state.poisonUserId) {
      users = users.map(user => user._id === this.state.poisonUserId ? { ...user, status: "Dead" } : user)
    }

    this.setState({ room: { ...this.state.room, users }, isReviewStatusState: true }, () => {
      this.endPoll()
      this.incrementCurrentState();
    });
  }

  setWakeUp = () => {
    this.updateUserStatusAndCheckEndGame();
    this.resetCurrentState();
  };

  renderMessages = () => {
    let messages = [];
    for (let message of this.state.messageBoard) {
      messages.push(<li>{message}</li>)
    }
    return messages;
  }

  resetMessageBoard = () => this.setState({messageBoard: [], currentStateMessage: ""});

  addMessage = (message) => this.setState({messageBoard: [...this.state.messageBoard, message]});

  renderNight = (customRoleSetting) => {
    let buttons = [];
    buttons.push(<Button variant="secondary" onClick={()=>this.setWerewolfVote()} disabled={(this.state.room.users.length < this.state.room.numberOfPlayers) || this.state.openTrialState === 'endPoll' || this.state.openTrialState === 'update'}>Start Night</Button>);
    buttons.push(<Button variant="secondary" onClick={()=>this.setSeerVote()} disabled={!this.showWerewolvesVictim().message}>Proceed to Seer</Button>);
    buttons.push(<Button variant="secondary" onClick={()=>this.setWitchAntidoteVote()} disabled={!this.state.currentStateMessage}>Proceed to Witch : Antidote</Button>);
    buttons.push(<Button variant="secondary" onClick={()=> this.setWitchPoisonVote()} disabled={!this.state.currentStateMessage}>Proceed to Witch : Poison</Button>)
    this.renderCustomRoleNightButton(buttons, customRoleSetting)
    buttons.push(<Button variant="secondary" onClick={()=>this.setSunrise()} disabled={!this.state.currentStateMessage}>Review Player's Status</Button>);
    buttons.push(<Button variant="secondary" onClick={()=>this.setWakeUp()}>Wake Up All Players</Button>);

    return (
      <>
        {buttons[this.state.currentState + 1]}
      </>

    )
  };

  renderCustomRoleNightButton (buttons, customRoleSetting) {
    Object.values(customRoleSetting)
      .filter(role => role.side === "villager" && role.quantity)
      .forEach(role => {
        let customRoleName = role.name.charAt(0).toUpperCase() + role.name.slice(1)
        buttons.push(<Button variant="secondary" onClick={() => this.setCustomVote(role.name)} disabled={!this.state.currentStateMessage}>Proceed to {customRoleName}</Button>)
      })
  }

  renderEndGameModal = () => {
    return (
      <Modal
        show={this.state.modalShow} backdrop="static" keyboard={false} size="lg"
        onHide={() => this.setState({modalShow:false})}
        aria-labelledby="contained-modal-title-vcenter" centered
        className={"end-modal"} style={{fontFamily: 'Comfortaa',textAlign:"center"}}
      >
        <EndModal room={this.state.room}
                  userStatus={this.props.userStatus}
                  isAutoEnd={this.state.isAutoEnd}
                  isWerewolfVictory={this.state.isWerewolfVictory}
                  routeHome={this.routeHome}
                  routeStart={this.routeStart}/>
      </Modal>
    )
  }

  render() {
    const { error, isLoaded, room } = this.state;
    let popover = () => (
      <Popover id="popover-basic">
        <Popover.Title as="h3" className={"text-center"}>
          No. of Players: {room.numberOfPlayers}
        </Popover.Title>
        <Popover.Content>
          <Table responsive borderless>
            <tbody>
            {this.renderRoleSettings(this.state.room.roleSetting)}
            </tbody>
          </Table>
        </Popover.Content>
      </Popover>
    );

    if (error) {
      return <div className={"text-white"}>Error: {error.message}</div>;
    } else if (!isLoaded || !room) {
      return <div className={"text-white"}>Loading Game...</div>;
    } else {
      // manipulate players' data
      let playerCards = [];
      for (let i=0; i<room.numberOfPlayers; i++) {
        let user = room.users[i];
        if (user) {
          playerCards.push(<HostPlayerCard user = {user}
                                           playerNumber={i+1}
                                           getUserById = {this.getUserById}
                                           rolesDetails = {this.state.room.roleSetting}
                                           currentState = {this.state.currentState}
                                           isReviewStatusState = {this.state.isReviewStatusState}
                                           alphawerewolfId = {this.state.alphawerewolfId}
                                           getWitch = {this.getWitch}
                                           antidote = {this.state.antidote}
                                           poison = {this.state.poison}
                                           statusChanged = {this.state.statusChanged}
                                           onDead={() => this.setUserStatus(user._id, "Dead")}
                                           onAlive={() => this.setUserStatus(user._id, "Alive")}/>);
        } else {
          playerCards.push(<PlayerCardEmpty/>);
        }
      }

      if (this.state.isPollActive && (this.state.currentState === -1 || this.state.currentState === 2 || this.state.currentState === 3 || this.state.currentState > 3)) {
        playerCards.push(
          <AbstainCard users={room.users}/>
        )
      }

      return (
        <div className="wrapper">
          <h1>
            <span style={{color:"antiquewhite", marginLeft:"10px"}} id={"we"}>We</span>
            <span style={{color:"antiquewhite"}} id={"re"}>re</span>
            <span style={{color:"#867E6E"}} id={"wolf"}>wolf</span>
            <span id={"remote"}>Remote</span>
          </h1>
          <div className={"game"}>
            <div className={"game__container"}>
              <span>
                MODERATOR
              </span>

              <div className={"text-right"}>
                <OverlayTrigger trigger="click" placement="left" overlay={popover()}>
                  <Button variant="light" className={"ml-auto mr-0"}>
                    <i className="fa fa-user"></i>
                  </Button>
                </OverlayTrigger>
              </div>

              <div className={"pregame-message"}
                   style={room.users.length === room.numberOfPlayers?{display:"none"}:{}}>
                <p>You may start game when all players join.</p>
                <p>All control buttons will unfreeze.</p>
              </div>

              {this.state.currentState !== -1 ? (
                <div className={"timeline"}>
                  <ul>
                    {this.getTimelineList(room.customRoles)}
                  </ul>
                </div>
              ): ""}

              {/* TODO: Remove Debugging Code*/}
              {/*<button onClick={()=> this.incrementCurrentState()}>*/}
              {/*Increment State: {this.state.currentState}*/}
              {/*</button>*/}
              {/*<button onClick={() => this.resetCurrentState()}>*/}
              {/*Reset State*/}
              {/*</button>*/}
              {/* TODO: Remove Debugging Code*/}

              {/*Votes for Specific Groups TODO: Remove once implemented */}
              {/*<button onClick={()=> this.startPoll('werewolf')}> state poll for werewolves </button>*/}
              {/*<button onClick={()=> this.startPoll('seer')}> state poll for seer </button>*/}
              {/*<button onClick={()=> this.startPoll('villager')}> state poll for villager</button>*/}
              {/*<button onClick={()=> this.startPoll('all')}> state poll for all</button>*/}

              {/*<Button variant="secondary">Begin the Night</Button>*/}
              {/*<Button variant="secondary">Proceed to Seer</Button>*/}
              {/*<Button variant="secondary">Proceed to Witch</Button>*/}
              {/*<Button variant="secondary">Proceed to Custom</Button>*/}
              {/*<Button variant="secondary">Begin the Night</Button>*/}

              {/*{this.renderNight()}*/}


              <div className={"wake-up"}>

                {/*<div className={"button-controls"}>*/}
                {/*{!this.state.isPollActive ?*/}
                {/*<Button className={"mr-1"} variant="light" onClick={()=>this.startPoll()} disabled={room.users.length < room.numberOfPlayers}>Start Poll</Button>*/}
                {/*: <Button className={"mr-1"} variant="light" onClick={()=>this.endPoll()}>End Poll</Button>}*/}
                {/*<Button className={`mr-1 ${this.state.isModified ? 'isModified' : 'd-none'}`} variant="secondary" onClick={()=>this.updateUserStatusAndCheckEndGame()} disabled={room.users.length < room.numberOfPlayers}>Update</Button>*/}
                {/*<Button className={"mr-1 end-game-btn"} variant="dark" onClick={()=>this.endGame()}>End Game</Button>*/}
                {/*</div>*/}

                <div className={"message-board"}
                     style={this.state.currentState !== -1 || this.state.displayMessageBoard ? {}:{display:"none"}}>
                  <p>Events</p>
                  <ul>
                    {this.renderMessages()}
                    <li>{this.state.currentStateMessage ? this.state.currentStateMessage : ''}</li>
                    <li>{this.state.currentState === 0 ? this.showWerewolvesVictim().message : ''}</li>
                  </ul>
                </div>

                <div className={"button-controls"}>
                  {this.renderNight(room.customRoles)}
                  {this.state.currentState === -1 ? (
                    <>
                      {/*Open Trial State: startPoll*/}
                      {this.state.openTrialState === 'startPoll' ?
                        <>
                          <Button className={"ml-1 mr-1"} variant="light" onClick={()=>this.startOpenTrial()} disabled={room.users.length < room.numberOfPlayers}>Open Trial</Button>
                          <br/>
                          <Button className={"ml-1 end-game-btn"} variant="dark" onClick={()=>this.endGame()}>End Game</Button>
                        </>
                          : ''}

                      {/*Open Trial State: endPoll*/}
                      {this.state.openTrialState === 'endPoll' ?
                        <Button className={"ml-1 mr-1"} variant="light" onClick={()=>this.endOpenTrial()}>End Poll</Button>
                          : ''}

                      {/*Open Trial State: update*/}
                      {this.state.openTrialState === 'update' ?
                        <Button className={`ml-1 isModified`} variant="secondary" onClick={()=>this.updateUserStatusAndCheckEndGame()}>Update</Button>
                          : ''}
                        </>
                  ) : ""}
                </div>

              </div>

              <div className={"pb-4"}>
                <span className={"room-id"}>Room: &nbsp;&nbsp; {room._id}</span>
              </div>
              <div className={"players"}>
                {playerCards}
              </div>

            </div>
          </div>

          {this.renderEndGameModal()}
        </div>
      );
    }
  }

  getTimelineList (customRoles) {
    let timelineList = []
    let customRoleItems = []
    let timelineItems = ["Werewolves", "Seer", "Witch", "Review", "Sunrise"]
    Object.values(customRoles)
      .filter(role => role.side === "villager" && role.quantity)
      .forEach(role => customRoleItems.push(role.name.charAt(0).toUpperCase() + role.name.slice(1)))
    timelineItems.splice(3, 0, ...customRoleItems)
    for (let i = 0; i < timelineItems.length; i++) {
      timelineList.push(<li className={this.getTimeLineStatus(i)}>{timelineItems[i]}</li>)
    }
    return (<>
      {timelineList}
    </>)
  }

}

export default withRouter(GameHost);
