import React, { Component } from "react";
import {Badge, Col, Container, Image, Row} from "react-bootstrap";
import "../GamePlayerCard/game-player-card.scss";

class GamePlayerCard extends Component {

  render() {
    let voteImages = [];

    if (this.props.votes) {
      for (let vote of this.props.votes) {
        if (!vote.abstain) {
          let voter = this.props.getUserById(vote.voter);
          if (this.props.alphawerewolfId.includes(vote.voter) && this.props.currentState === 0) {
            voteImages.push(
            <>
            <Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />
            <Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />
            </>
            )
          } else {
            voteImages.push(<Image className={"mt-2 mb-3 mr-1 player-image"} width={"30"} height={"30"} src={voter.picture} alt={voter.name} roundedCircle />)
          }
        }
      }
      // if (voteImages.length === 0) {
      //   voteImages.push(<div style={{fontSize:"12px", marginTop:"6px"}}>No Recorded Vote</div>)
      // }
    } 
    // else {
    //   voteImages.push(<div style={{fontSize:"12px", marginTop:"6px"}}>Poll Pending</div>)
    // }

    return (
      <div className={`player ${this.props.status === "Alive" ? "" : "dead"} ${this.props.isSelected && !this.props.isCurrentUserDead ? 'selected' : ''} ${this.props.isCurrentUserDead ? 'disable-cursor' : ''}${this.props.statusChanged.includes(this.props.id)? "status-changed" : ""}`} onClick={this.props.onClick} style={{boxShadow: "antiquewhite 0px 0px 5px, antiquewhite 0px 0px 3px, antiquewhite 0px 0px 3px", marginBottom: "8px"}}>
        <div className={"you"} style={this.props.ownId === this.props.id? {}: {display:"none"}}>YOU</div>
        <Container>
          <Row>
            <Col xs={"auto"} className={"text-center"}>
              <Image className={"mt-2 player-image"} src={this.props.picture} roundedCircle width={"65"} height={"65"} style={{marginBottom: "0.5rem"}}/>
            </Col>
            <Col xs={"auto"}>
              <Row>
                <Col xs={"auto"} style={{marginTop:"10px"}}>
                  <Badge variant="dark" className={"mr-1"}>{this.props.playerNumber}</Badge>
                  <span className={"player__name "} style={{fontSize:"1.1em"}}>{this.props.name.length >= 10? this.props.name.substring(0,9).concat("..."): this.props.name}</span>
                </Col>
              </Row>
              <Row className={"pl-3"}>
                {voteImages}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default GamePlayerCard;
