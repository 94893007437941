import React, { Component } from 'react';
import './role-card.scss'
import {Modal} from "react-bootstrap";

class RoleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {expanded: false};
  }

  setExpanded = (expanded) => this.setState({expanded});

  render() {
    let role = this.props.role;
    // Sample: role = {name: 'Werewolf', thumbnail: 'https://via.placeholder.com/300', details: 'Select one player to heal each night. If this player is attacked by the Werewolves, they don\'t die that night. The Doctor cannot heal themselves.'}
    return (
      <>
        <div className={"role-card"} onClick={() => this.setExpanded(true)}>
          <img src={role.thumbnail} width={65} height={65} alt={role.name}/>
          <p className={"role-name"}>{role.name}</p>
        </div>
        <Modal show={this.state.expanded} onHide={() => this.setExpanded(false)} centered className={"role-card-modal"}>
          <Modal.Header closeButton />
          <Modal.Body>
            <img src={role.thumbnail} alt={""} width={300} height={300} alt={role.name} style={{borderRadius:"20px", filter: "drop-shadow(0px 0px 25px)"}}/>
            <p className={"role-name"}>{role.name}</p>
            <p className={"role-details"}>{role.details}</p>
          </Modal.Body>
        </Modal>
      </>
    )
  }
}

export default RoleCard;
