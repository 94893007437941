import React from 'react';
import { withRouter } from "react-router-dom";
import {
  OverlayTrigger,
  Popover,
  Table,
  Modal,
  Tooltip
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axios from 'axios';
import GamePlayerCard from "../GamePlayerCard/game-player-card";
import AbstainCard from '../AbstainCard/abstain-card';
import PlayerCardEmpty from "../PlayerCardEmpty/player-card-empty";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EndModal from "../EndModal/end-modal";
import io from "socket.io-client";
import './game-player.scss';
import * as Constants from "../Landing/roleConstants";

toast.configure()
class GamePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      host: true,  // determined by session id?
      numberOfPlayers: "",
      roleSetting: [],
      room: {users:[]},
      statusChanged: [],
      users:[],
      poll: false,
      pollSelection: null,
      roomId: window.location.href.split("/").pop(),
      modalShow: false,
      voting: false,
      votingRole: '',
      message: '',
      alphawerewolfId: [],
      currentState: '',
      witchPollType: '',
      antidote: true,
      poison: true,
    };
    const roomId = window.location.href.split("/").pop();
    this.socket = io.connect('/' + roomId);
    this.socket.on('update players', (data) => {
      console.log('Host pushed an update to players');
      this.getRoom(roomId);
    })
    this.socket.on('poll start', (data) => {
      this.setState({statusChanged: [], votingRole: data.role})
      let currentUser = this.getCurrentUser()
      let poll = false
      console.log('Poll started for: ' + data.role)
      this.setState({pollRole: data.role})
      if (data.role === "all") {
        poll = true
        this.setState({message: 'Trial in progress, please vote.'})
      } else if (data.role === currentUser.role || data.role === currentUser.side) {
        if (data.role !== 'werewolf' && data.role !== 'alpha werewolf' && currentUser.status === 'Dead') {
          const thisUserName = currentUser.name.toUpperCase()
          const thisUserRole = currentUser.role.charAt(0).toUpperCase() + currentUser.role.substr(1)
          let msg = thisUserRole + " (" + thisUserName + ") is dead."
          this.socket.emit("update timeline message", msg)
        }
        if (data.role === "werewolf" && data.role === currentUser.side) {
          poll = true
          this.setState({message: 'Werewolf team, please select exactly 1 player to kill.', alphawerewolfId: data.alphawerewolfId, currentState: data.currentState})
        } else if (data.role === 'seer' && currentUser.status !== "Dead") {
          poll = true
          this.setState({message: 'Seer, please select 1 player to reveal his/her identity.'})
        } else if (data.role === 'witch' && currentUser.status !== "Dead") {
          if (data.pollType !== 'poison') {
            if (this.state.antidote) {
              poll = true
              this.setState({
                witchPollType: 'antidote',
                message: `<p>${data.victim} was killed. You have ${+ currentUser.antidote} antidote left.</p><p>Select ${data.victim} if you want to save him/her.</p>`
              })
            } else {
              let msg = "Witch (" + currentUser.name.toUpperCase() + ") has no antidote left."
              this.socket.emit("update timeline message", msg)
              this.setState({
                message: `<p>${data.victim} was killed. You have no antidote left.</p>`
              })
            }
          } else {
            if (this.state.poison) {
              poll = true
              this.setState({
                witchPollType: 'poison',
                message: `<p>You have ${+ currentUser.poison} poison left.</p><p>Select a player if you want to poison him/her.</p>`
              })
            } else {
              let msg = "Witch (" + currentUser.name.toUpperCase() + ") has no poison left."
              this.socket.emit("update timeline message", msg)
              this.setState({
                message: `<p>You have no poison left.</p>`
              })
            }
          }
        } else {
          poll = true
          if (this.getCurrentUser().status === "Alive") {
            this.setState({message: "Please select 1 player."})
          }
        }
      }
      this.clearUiVotes()
      console.log("poll", poll)
      this.setState({poll, pollSelection: null})
    })
    this.socket.on('poll end', (data) => {
      console.log('Poll end');

      if (this.state.votingRole === 'witch' && this.state.witchPollType === 'antidote' && this.state.pollSelection) {
        let users = this.state.room.users;
        users = users.map(user => user.role === 'witch' ? {...user, antidote: false} : user)
        this.setState({room: {...this.state.room, users}, antidote: false})
      }
      if (this.state.votingRole === 'witch' && this.state.witchPollType === 'poison' && this.state.pollSelection) {
        let users = this.state.room.users;
        users = users.map(user => user.role === 'witch' ? {...user, poison: false} : user)
        this.setState({room: {...this.state.room, users}, poison: false})
      }
      this.setState({
        poll: false,
        message: '',
        alphawerewolfId: [],
        currentState: '',
        witchPollType: ''
      });
    })
    this.socket.on('game end', (data) => {
      console.log('Game end');
      this.setState(data)
      this.setState({ modalShow: true })
      document.getElementById('root').style.filter = 'blur(5px)';
    })
  }

  getCurrentUser = () => {
    return this.state.room.users.filter(user => this.props.user._id === user._id)[0];
  }

  /**
   * Clear client side votes only
   */
  clearUiVotes () {
    let newUsers = this.state.room.users.map(user => {return {...user, votes: [], ballot:[]}})
    this.setState({room: {...this.state.room, users: newUsers}})
  }

  getRoom(roomId) {
    let originalUsers = this.state.room.users;
    axios.get(`/rooms/${roomId}`)
        .then(res => {
          const room = res.data;
          this.setState({isLoaded: true, room, }, () => {
            let updatedUsers = room.users;
            let statusChanged = [];
            for (let i = 0; i < originalUsers.length; i++) {
              if (originalUsers[i].status !== updatedUsers[i].status) {
                statusChanged.push(originalUsers[i]._id);
              }
            }
            this.setState({statusChanged})
          });
        })
        .catch(error => {
          this.setState({isLoaded: true, error})
        })
  }

  componentDidMount() {
    const roomId = window.location.href.split("/").pop();
    this.getRoom(roomId);
  }

  setPollSelection = user => {

    const villagerSide = (user) => {
      toast.success(`${user.name.toUpperCase()} is on ${user.side}'s side!`, {
        position: toast.POSITION.TOP_RIGHT,
        progressStyle:{ fontFamily: "Comfortaa" },
        autoClose: 8000,
      })
    }
    const werewolfSide = (user) => {
      toast.error(`${user.name.toUpperCase()} is on ${user.side}'s side!`, {
        position: toast.POSITION.TOP_RIGHT,
        progressStyle:{ fontFamily: "Comfortaa" },
        autoClose: 8000,
      })
    }
    // Disable Polling for Dead Users
    if (this.getCurrentUser().status === 'Dead') {
      return
    }

    // Abstain Vote
    if (user === "abstain" && !this.state.voting) {

      this.setState({pollSelection: "", voting: true})
      axios.post(`/rooms/${this.state.roomId}/vote`, {
        voter: this.props.user._id,
        candidate: "",
      })
        .then(res => {
          console.log("vote casted")
          // Notify other players and get latest room
          this.socket.emit('update players', 'refresh getRoom');
          let msg = ""
          const currentUser = this.getCurrentUser()
          const thisUserName = currentUser.name.toUpperCase()
          const thisUserRole = currentUser.role.charAt(0).toUpperCase() + currentUser.role.substr(1)
          if (this.state.votingRole === "witch" ) {
            if (this.state.witchPollType === "poison") {
              msg = "Witch (" + thisUserName + ") did not poison anyone."
            }
            if (this.state.witchPollType === "antidote") {
              msg = "Witch (" + thisUserName + ") did not save anyone."
            }
          } else if (this.state.votingRole !== "all" || this.state.votingRole !== "") {
            msg = thisUserRole + " (" + thisUserName + ") abstained from choosing any player."
          }
        this.socket.emit("update timeline message", msg)
          this.socket.emit("update timeline message", msg)
          this.getRoom(this.state.roomId);
          this.setState({voting: false});
        })
        .catch(err => {
          console.log(err)
          this.setState({voting: false});
        })

    } else if (user.status !== "Dead" && !this.state.voting && user._id !== this.state.pollSelection) {
      // Non-abstain Vote
      this.setState({pollSelection: user._id, voting: true})
      axios.post(`/rooms/${this.state.roomId}/vote`, {
        voter: this.props.user._id,
        candidate: user._id,
      })
        .then(res => {
          console.log("vote casted")
          // Notify other players and get latest room
          this.socket.emit('update players', 'refresh getRoom');
          let msg = ""
          const currentUser = this.getCurrentUser()
          const selectedUserRole = user.role.charAt(0).toUpperCase() + user.role.substr(1)
          const selectedUserName = user.name.toUpperCase()
          const thisUserName = currentUser.name.toUpperCase()
          const thisUserRole = currentUser.role.charAt(0).toUpperCase() + currentUser.role.substr(1)
          if (this.state.votingRole === "seer") {
            msg = "Seer (" + thisUserName + ") checked the identity of " + selectedUserRole + " (" + selectedUserName + ")."
          } else if (this.state.votingRole === "witch" ) {
            if (this.state.witchPollType === "poison") {
              msg = "Witch (" + thisUserName + ") used poison to kill " + selectedUserRole + " (" + selectedUserName + ")."
            }
            if (this.state.witchPollType === "antidote") {
              msg = "Witch (" + thisUserName + ") used antidote to save " + selectedUserRole + " (" + selectedUserName + ")."
            }
          } else if (this.state.votingRole !== "all" && currentUser.side !== "werewolf") {
            msg = thisUserRole + " (" + thisUserName + ") selected " + selectedUserRole + " (" + selectedUserName + ")."
          }
          this.socket.emit("update timeline message", msg)
          this.getRoom(this.state.roomId);
          this.setState({voting: false});

          // Seer special vote revealing player's role with an alert
          // When the vote is casted, the host sees that seer is 'voted'
          // Seer gets an alert with information of the player's role
          if (this.state.votingRole === 'seer') {
            // alert("This player is on " + user.side + "'s side. ");
            if (user.side === 'villager') {
              {villagerSide(user)}
            } else {
              {werewolfSide(user)}
            }
            this.setState({poll: false, votingRole: ''})
          }
        })
        .catch(err => {
          console.log(err)
          this.setState({voting: false});
        })
    }
  }

  getUserById = (userId) => {
    return this.state.room.users.find((user) => user._id === userId)
  }

  routeHome = () => {
    document.getElementById('root').style.filter = 'none';
    this.props.history.push("/");
  }

  renderRoleSettings = (roleSetting) => {
    let rendering = []
    for (let role in roleSetting) {
      let roleItem = roleSetting[role]
      let name = roleItem.name;
      let count = roleItem.quantity;
      if (count > 0) {
        rendering.push(
          <tr>
            <td><strong style={{textTransform: "capitalize"}}>{name}</strong></td>
            <td>{count}</td>
          </tr>)
      }
    }
    return rendering
  }

  getRoleDetails = (roleSetting, roleName) => {
    if (roleSetting && roleName) {
      return roleSetting[roleName.toLowerCase()].details;
    }
  }

  getWitch = () => {
    return this.state.room.users.filter(user => user._id === this.props.user._id)[0];
  }

  render() {
    const { error, isLoaded, room } = this.state;

    let popover = () => (
        <Popover id="popover-basic">
          <Popover.Title as="h3" className={"text-center"}>
            No. of Players: {room.numberOfPlayers}
          </Popover.Title>
          <Popover.Content>
            <Table responsive borderless>
              <tbody>
              {this.renderRoleSettings(this.state.room.roleSetting)}
              </tbody>
            </Table>
          </Popover.Content>
        </Popover>
    );

    if (error) {
      return <div className={"text-white"}>Error: {error.message}</div>;
    } else if (!isLoaded || !room) {
      return <div style={{color:"antiquewhite"}}>Loading Game...</div>;
    } else {

      // let gamePlayerCards = room.users.map(user => <GamePlayerCard name={user.name} playerNumber={"1"}/>);
      let gamePlayerCards = [];
      for(let i=0; i<room.numberOfPlayers; i++){
        let user = room.users[i];
        if (user) {
          if (this.state.poll) {
            gamePlayerCards.push(
              <GamePlayerCard onClick={() => this.setPollSelection(user)}
                              isSelected={this.state.pollSelection === user._id}
                              votes={user.votes}
                              getUserById = {this.getUserById}
                              isCurrentUserDead = {this.getCurrentUser().status === 'Dead'}
                              currentState = {this.state.currentState}
                              statusChanged = {this.state.statusChanged}
                              alphawerewolfId = {this.state.alphawerewolfId}
                              name={user.name}
                              id={user._id}
                              status={user.status}
                              playerNumber={i+1}
                              picture={user.picture}
                              ownId={this.props.user._id}/>);
          } else {
            gamePlayerCards.push(
              <GamePlayerCard ownId={this.props.user._id} name={user.name} id={user._id} status={user.status} playerNumber={i+1} picture={user.picture} statusChanged = {this.state.statusChanged}/>);
          }
        } else {
          gamePlayerCards.push(<PlayerCardEmpty/>);
        }
      }

      if (this.state.poll && (this.getCurrentUser().side !== "werewolf" || this.state.pollRole === 'all') && this.state.votingRole !== "seer") {
        gamePlayerCards.push(
          <AbstainCard onClick={() => this.setPollSelection("abstain")}
                       isSelected={this.state.pollSelection === ""}
                       users={room.users} />
        )
      }

      return (
        <div className={this.state.poll ? "wrapper poll" : "wrapper"}>
          <h1><span style={{color:"antiquewhite"}} id={"we"}>We</span><span style={{color:"antiquewhite"}} id={"re"}>re</span><span style={{color:"#867E6E"}} id={"wolf"}>wolf</span><span id={"remote"}>Remote</span></h1>
          <button className={"debug-mode"} onClick={() => this.setState({poll: !this.state.poll})}>Enable / Disable Poll</button>
          <div className={"game"}>
            <div className={"game__container"} style={{position: "relative"}}>
              <span style={{position: "absolute", top: "-40px", left: 0, color: "darkgrey", marginLeft: "30px"}}>Room: &nbsp;&nbsp;{room._id}</span>
              <div>

                <div style={{position: "absolute", right: "10px", top: "10px"}}>
                <OverlayTrigger trigger="click" placement="left" overlay={popover()}>
                  <Button variant="light" className={"float-right mb-1"}><i className="fa fa-user"></i></Button>
                </OverlayTrigger>
                </div>

                <div style={{marginBottom:"25px"}}>
                  <p className={"text-center"} style={{textTransform: 'capitalize', color:"white"}}>{this.props.name.length >= 10? this.props.name.substring(0,9).concat("..."): this.props.name}, <span style={{color:"white", textTransform: 'none'}}>your role is</span></p>
                  <p className={"text-center h3"} style={{textTransform: 'uppercase', color:"antiquewhite"}}>
                    {/* {this.props.user ? (this.props.user.role === "alpha werewolf"? "alpha werewolf": this.props.user.role) : "HOST"} */}
                    {this.props.user ? this.props.user.role : "HOST"}

                      <OverlayTrigger
                        placement={"bottom"}
                        overlay={
                          <Tooltip id={`tooltip-bottom`}>
                            {this.getRoleDetails(this.props.rolesDetails, this.props.user.role)}
                          </Tooltip>
                        }
                      >
                        <sup><i className="fa fa-question-circle"></i></sup>
                      </OverlayTrigger>
                  </p>
                  <p style={this.props.user.role === 'witch'?{textAlign:"center"}:{display:'none'}}><span>antidote: {+ (this.getWitch().antidote && this.state.antidote)}</span>&nbsp;&nbsp;<span>poison: {+ (this.getWitch().poison && this.state.poison)}</span></p>

                </div>
              </div>
              <div className={"role-turn-message"} style={{textAlign:"center", marginBottom:"12px", fontSize:"12px", maxWidth:"300px"}}>
                {/* {this.state.message} */}
                <div dangerouslySetInnerHTML={{ __html: this.state.message }} style={{color:"lawngreen", fontSize:"16px"}} />
              </div>
              <div className={"players"}>
                {gamePlayerCards}
              </div>

            </div>
          </div>
          <Modal
            show={this.state.modalShow}
            onHide={() => this.setState({modalShow:false})}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{fontFamily: 'Comfortaa',textAlign:"center"}}
            className={"end-modal"}
          >
            <EndModal room={room}
                      userStatus={this.props.userStatus}
                      isAutoEnd={this.state.isAutoEnd}
                      isWerewolfVictory={this.state.isWerewolfVictory}
                      routeHome={this.routeHome}/>
          </Modal>
        </div>
      );
    }
  }
}

export default withRouter(GamePlayer);
